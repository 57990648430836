
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import pdf from "vue-pdf"
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { getUuid } from "@/utils/common";
import Template from "@/views/dashboard/template.vue";
import StepList from "@/components/scope/step-list.vue";
const base = namespace("base");

@Component({
  model: {
    prop: "fileUrls",
    event: "change"
  },
  components: { pdf }
})
export default class FileUpload extends Vue {
  @base.Action getUploadToken;
  @base.Action getOssAccessUrl;
  @Prop({ default: 1 })
  maxNum: number;

  @Prop({ default: "image" }) // image video
  fileType: string;

  @Prop({ default: () => [] })
  defaultFileList: any;

  @Prop({ default: false, type: Boolean })
  readonly: boolean;
  @Prop({ default: "PUBLIC", type: String })
  bucketType: String;

  @Prop({ default: () => [] })
  fileUrls: any;

  @Prop({
    default: () => ({ businessCode: "defalut", businessType: "4" })
  })
  uploadBusinessInfo: any;
  isLoading: boolean = false;
  get allUrl() {
    return this.fileList.map(item => item.url);
  }
  uploadUrl: string = "";
  uploadTokenObj: any = {};
  fileList: any = [];
  uploadFileCb(file) {
    return this.getUploadToken({
      businessType: "0",
      businessCode: "000000000",
      bucketType: this.bucketType,
    }).then(data => {
      this.uploadTokenObj = {
        url: data.data.host,
        key: `${data.data.dir}/${getUuid(15)}/${file.name}`,
        policy: data.data.policy,
        OSSAccessKeyId: data.data.accessId,
        Signature: data.data.signature,
        success_action_status: 200,
        domain: data.data.domain
      };
      this.uploadUrl = data.data.host;
    });
  }
  async beforeAvatarUpload(file) {
    if (this.fileType === "video" && file.type !== "video/mp4") {
      this.$message.warning("仅支持上传MP4格式的视频文件");
      return;
    } else {
      this.isLoading = true;
      await this.uploadFileCb(file);
    }
  }
  handleUploadSuccess(response, file, fileList) {
    if (file.status === "success") {
      if(this.bucketType === "PRIVATE"){
        this.$emit("uploadSuccess", this.uploadTokenObj.key);
        this.getOssAccessUrl({url: this.uploadTokenObj.key}).then(res => {
          this.fileList.push({
            name: file.name,
            url: res.data
          });
        })
      }else{
        this.$emit("uploadSuccess", this.uploadTokenObj.domain + "/" + this.uploadTokenObj.key);
        this.fileList.push({
          name: file.name,
          url: this.uploadTokenObj.domain + "/" + this.uploadTokenObj.key
        });
      }

      console.log("fileList push后的长度:" + fileList.length);
      this.emitChange();
      this.isLoading = false;
    }
  }
  handleDeleteImg(i) {
    this.fileList.splice(i, 1);
    this.emitChange();
  }
  emitChange() {
    if (this.maxNum <= 1) {
      if (this.fileList.length > 0) {
        this.$emit("change", this.fileList[0].url);
      } else {
        this.$emit("change", "");
      }
    } else {
      this.$emit(
        "change",
        this.fileList.map(item => item.url)
      );
    }
  }
  defaultUrl: any = [];
  @Watch("defaultFileList", {
    immediate: true
  })
  watchDefaultFileList(val) {
    if (val.length) {
      val.forEach(item => {
        if (item.url && !this.defaultUrl.includes(item.url)) {
          console.log("[ this.fileList ]", this.fileList);
          this.fileList.push(item);
          this.defaultUrl.push(item.url);
          this.emitChange();
        }
      });
    } else {
      this.fileList = [];
      this.defaultUrl = [];
      this.emitChange();
    }
  }
  clearFiles() {
    this.fileList = [];
  }

}
