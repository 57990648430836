
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Pageniation as Table } from "@/utils/decorator";
import t from "@common/src/i18n/t";
const base = namespace("base");
const learning = namespace("learning");
import FileUpload from "@/components/scope/file-upload.vue";
const serviceItem = namespace("service-item");
const app = namespace("app");
const packageInfo = namespace("packageInfo");


@Component({
  components: {FileUpload}
})
@Table("loadListData")
export default class PackageInfoEdit extends Vue {
  @base.Action getDictionaryList;
  @serviceItem.Action getServiceList;
  @serviceItem.Action getServiceDetail;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @packageInfo.Action packageInfoDetail;
  @packageInfo.Action savePackageInfo;


  storeTypeList: any = [];
  serviceItemList: any = [];
  packageFormData: any = {
    packageCode: '',
    packageName: '',
    price: '',
    discountPrice: '',
    discountRate: '',
    detailImg: '',
    status: '',
    storeType: '',
    serviceItems: []
  }
  packageFormDataRule: any = {
    packageName: [{ required: true, message: "填写名称", trigger: "blur" }],
    discountRate: [{ required: true, message: "填写折扣", trigger: "blur" }],
    status: [{ required: true, message: "选择状态", trigger: "blur" }],
    detailImg: [{ required: true, message: "上传图片", trigger: "blur" }],
    price: [{ required: true, message: "填写服务项", trigger: "blur" }],
    storeType: [{ required: true, message: "选择门店类型", trigger: "blur" }]
  }

  get packageCode() {
    let val = this.$route.params.packageCode;
    return (val || "");
  }

  created() {
    this.getDictionaryList("store_type").then(data => {
      this.storeTypeList = data;
    });
    if (this.packageCode.length > 0) {
      this.packageInfoDetail({packageCode:this.packageCode}).then(data => {
        this.packageFormData = data.data;
      })

    }
  }

  searchServiceItem(val) {
    this.getServiceItemList(val);
  }
  getServiceItemList(name = "") {
    return this.getServiceList({
      pageSize: 20,
      pageNum: 1,
      searchKey: name,
      // storeTypeArray: this.orderFormData.storeTypes
    }).then(data => {
      this.serviceItemList = data.data.list || [];
      console.log("服务项列表")
      console.log(data)
    });
  }

  handleChooseServiceItem(val, index) {
    const haveSameService = this.packageFormData.serviceItems.some((item, serviceRowIndex) => {
      return serviceRowIndex !== index && item.serviceCode === val;
    });
    if (haveSameService) {
      this.$message.error(t("marketing.not-choose-repeat-project") as string);
      this.packageFormData.serviceItems[index].serviceCode = "";
      return;
    }
    this.getServiceDetail({
      serviceCode: val
    }).then(data => {
      data = data.data || {};
      const serviceItemData = Object.assign({}, this.definitionServiceData, {
        packageCode: this.packageCode,
        serviceCode: data.serviceCode,
        serviceNum: "",
        serviceFee: data.serviceFee,
        price: "",
        discountPrice: ""
      });
      this.packageFormData.serviceItems.splice(index, 1, serviceItemData);
    });
  }

  handleAddService() {
    if (this.packageFormData.storeType.length < 1) {
      return this.$message.error("请选择适用门店类型");
    }
    this.packageFormData.serviceItems.push(Object.assign({}, this.definitionServiceData));
    this.searchServiceItem("");
  }
  definitionServiceData: any = {
    packageCode: this.packageCode,
    serviceCode: "",
    serviceNum: "",
    serviceFee: "",
    price: "",
    discountPrice: ""
  };

  deleteItem(index) {
    this.packageFormData.serviceItems.splice(index, 1);
    this.computedTotalAmount();
  }

  computedAmount(index) {
    const currentServiceItem = this.packageFormData.serviceItems[index];
    if (this.packageFormData.discountRate.length < 1) {
      currentServiceItem.serviceNum = "";
      return this.$message.error("请输入折扣");
    }
    const amount = currentServiceItem.serviceFee * 1;
    currentServiceItem.price = amount.toFixed(2);
    currentServiceItem.discountPrice = (currentServiceItem.price* this.packageFormData.discountRate).toFixed(2);
    this.computedTotalAmount();
  }
  computedTotalAmount() {
    const serviceTotal = this.packageFormData.serviceItems.reduce((preV, curV) => preV + Number(
        (curV.serviceNum || 0) * curV.price), 0);
    if (Object.is(NaN, serviceTotal)) {
      this.$message.error("请输入数值格式的数量");
    }
    this.packageFormData.price = serviceTotal.toFixed(2);
    let discountPrice = this.packageFormData.price * this.packageFormData.discountRate;
    this.packageFormData.discountPrice = discountPrice.toFixed(2);
  }

  handleDiscountRate(){
    console.log(this.packageFormData.serviceItems)
    for (let index in this.packageFormData.serviceItems) {
      let currentServiceItem = this.packageFormData.serviceItems[index];
      console.log(currentServiceItem)
      const amount = currentServiceItem.serviceFee * 1;
      currentServiceItem.price = amount.toFixed(2);
      currentServiceItem.discountPrice = (currentServiceItem.price* this.packageFormData.discountRate).toFixed(2);
    }
    this.computedTotalAmount();
  }

  submitForm(formName) {
    (this.$refs.refPackageInfoForm as any).validate(valid => {
      if (valid) {
        this.btnStartLoading();
        this.savePackageInfo(this.packageFormData).then(data => {
          this.$message.success(this.$t("base.success") as any);
          this.$router.go(-1);
        })
        .finally(item => {
          this.btnStopLoading();
        });
      } else {
        console.log("error submit!!");
      }
    });
  }
}
