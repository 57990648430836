export function Table(loadDataMethodName = "loadListData", options = { pageSize: 10 }) {
  return function(target) {
    function loadData(target) {
      loadDataMethodName && target[loadDataMethodName]();
    }
    target.prototype.mix_pageNum = 1;
    target.prototype.mix_pageSize = options.pageSize || 10;
    target.prototype.mix_total = 0;
    target.prototype.mix_loading = false;
    target.prototype.mix_sortParams = [
      {
        item: "",
        type: ""
      }
    ];
    target.prototype.mix_handleSizeChange = function(size) {
      this.mix_pageNum = 1;
      this.mix_pageSize = size;
      loadData(this);
    };
    target.prototype.mix_handlePageChange = function(pageNum) {
      this.mix_pageNum = pageNum;
      loadData(this);
    };
    target.prototype.mix_handleTableSort = function({ prop, order }) {
      this.mix_pageNum = 1;
      this.mix_sortParams = [
        {
          item: order ? prop : "",
          type: order ? (order === "ascending" ? "asc" : "desc") : ""
        }
      ];
      loadData(this);
    };
  };
}

Table.LoadDataMethod = function() {
  return function(target, name, descriptor) {
    const func = descriptor.value;
    descriptor.value = async function(...args: any[]) {
      this["mix_loading"] = true;
      try {
        const result = await func.apply(this, args);
        if (typeof result === "number") {
          this.mix_total = result;
        } else {
          this.mix_total = result.data.total;
        }
        return result;
      } catch (e) {
        this.mix_total = 0;
        console.error("分页数据格式有误2");
      } finally {
        this["mix_loading"] = false;
      }
    };
  };
};

Table.DataImport = function(options) {
  return function(target) {
    target.prototype.mix_showImportModal = false;
    target.prototype.mix_templateUrl = options.templateUrl;
    target.prototype.mix_actionUrl = options.actionUrl;
    target.prototype.mix_handleShowImportModal = function() {
      this.mix_showImportModal = true;
    };
  };
};
export function Pageniation(loadFucName = "loadListData") {
  return function(target) {
    function loadData(target) {
      loadFucName && target[loadFucName]();
    }
    target.prototype.mix_pageNum = 1;
    target.prototype.mix_pageSize = 10;
    target.prototype.mix_total = 0;
    target.prototype.mix_loading = false;
    target.prototype.mix_sortParams = [
      {
        item: "",
        type: ""
      }
    ];
    target.prototype.mix_handleSizeChange = function(size) {
      this.mix_pageNum = 1;
      this.mix_pageSize = size;
      loadData(this);
    };
    target.prototype.mix_handlePageChange = function(pageNum) {
      this.mix_pageNum = pageNum;
      loadData(this);
    };
    target.prototype.mix_handleTableSort = function({ prop, order }) {
      this.mix_pageNum = 1;
      this.mix_sortParams = [
        {
          item: order ? prop : "",
          type: order ? (order === "ascending" ? "asc" : "desc") : ""
        }
      ];
      loadData(this);
    };
  };
}

Pageniation.LoadDataMethod = function() {
  return function(target, name, descriptor) {
    const func = descriptor.value;
    descriptor.value = async function(...args: any[]) {
      this["mix_loading"] = true;
      try {
        const result = await func.apply(this, args);
        if (typeof result === "number") {
          this.mix_total = result;
        } else {
          this.mix_total = result.data.total;
        }
        return result;
      } catch (e) {
        this.mix_total = 0;
        console.error("分页数据格式有误2");
      } finally {
        this["mix_loading"] = false;
      }
    };
  };
};

Pageniation.DataImport = function(options) {
  return function(target) {
    target.prototype.mix_showImportModal = false;
    target.prototype.mix_templateUrl = options.templateUrl;
    target.prototype.mix_actionUrl = options.actionUrl;
    target.prototype.mix_handleShowImportModal = function() {
      this.mix_showImportModal = true;
    };
  };
};

export function Loading(loadingKey?) {
  return function(target, name, descriptor) {
    const func = descriptor.value;
    descriptor.value = async function(...args: any[]) {
      this["mix_loading"] = true;
      try {
        const result = await func.apply(this, args);
        this.mix_total = result && result.data.total;
        return result;
      } catch (e) {
        this.mix_total = 0;
        console.log("decorator Loading error:", e);
      } finally {
        this["mix_loading"] = false;
      }
    };
  };
}

export function TableRowsSelect(options = { warningMsg: "", updateStatusFuncName: "", tableRefKey: "" }) {
  return function(target) {
    target.prototype.mix_showForbidTips = false;
    target.prototype.mix_selectedRows = [];
    target.prototype.mix_handleBatchForbid = function() {
      if (this.mix_selectedRows.length < 1) {
        this.$message.warning(options.warningMsg);
      } else {
        this.mix_showForbidTips = true;
      }
    };
    target.prototype.mix_handleBatchStart = function() {
      if (this.mix_selectedRows.length < 1) {
        this.$message.warning(options.warningMsg);
      } else {
        this[options.updateStatusFuncName](1);
      }
    };
    target.prototype.mix_handleCheckAll = function(tableDataList) {
      tableDataList.forEach(item => {
        (this.$refs[options.tableRefKey] as any).toggleRowSelection(item, true);
      });
    };
    target.prototype.mix_handleCancelAll = function() {
      (this.$refs[options.tableRefKey] as any).clearSelection();
    };
    target.prototype.mix_handleRowSelect = function(selection, row) {
      this.mix_selectedRows = selection;
    };
  };
}

TableRowsSelect.updateMethod = function(target, name, descriptor) {
  const func = descriptor.value;
  descriptor.value = async function(...args: any[]) {
    try {
      const result = await func.apply(this, args);
      console.log(333);
      return result;
    } catch (e) {
      console.log("TableRowsSelect.updateCallback", e);
    } finally {
      this["mix_showForbidTips"] = false;
    }
  };
};
